import React, { useEffect, useState } from "react";

function chunk(str: string, n: number) {
	const result: string[] = [];
	const parts: string[] = str.split(" ");
	if (!parts.length) {
		return [str];
	}
	let currentChunk: string = "";
	let i = 0;
	for (i = 0; i < parts.length; i++) {
		if (parts[i].length > n) {
			result.push(currentChunk);
			result.push(parts[i].slice(0, n) + " ");
			currentChunk = parts[i].slice(n) + " ";
		} else {
			if (currentChunk.length + parts[i].length + 1 > n) {
				result.push(currentChunk);
				currentChunk = "";
			}
			currentChunk += parts[i] + " ";
			if (currentChunk.length >= n) {
				result.push(currentChunk);
				currentChunk = "";
			}
		}
	}
	if (currentChunk.length) {
		result.push(currentChunk);
	}
	return result;
}

const Goal = (props: {
	goal: { title: string; sections: string[] };
	setGoal: (goal: { title: string; sections: string[] }) => void;
	deleteGoal: () => void;
}) => {
	return (
		<div>
			<input
				placeholder="Goal title"
				className="w-full bg-slate-500 rounded p-4 mt-5 outline-none dark:bg-slate-600 bg-slate-300"
				value={props.goal.title}
				onChange={({ target: { value } }) =>
					props.setGoal({ ...props.goal, title: value })
				}
			/>
			<div className="flex flex-wrap mt-3 ml-10">
				{props.goal.sections.map((section, index) => (
					<div className="w-full flex gap-4 mt-3" key={index}>
						<textarea
							className="h-8 w-full bg-slate-500 rounded p-4 outline-none dark:bg-slate-600 bg-slate-300"
							value={section}
							onChange={({ target: { value } }) =>
								props.setGoal({
									...props.goal,
									sections: props.goal.sections.map((s, i) =>
										i === index ? value : s
									),
								})
							}
						/>
						<button
							onClick={() => {
								props.setGoal({
									...props.goal,
									sections: props.goal.sections.filter((_, i) => i !== index),
								});
							}}
							className="p-4 rounded bg-red-500"
						>
							delete
						</button>
					</div>
				))}
			</div>
			<div className="mt-5 ml-10">
				<button
					className="bg-green-500 p-4 rounded w-full opacity-80 hover:opacity-100 transition-opacity"
					onClick={() =>
						props.setGoal({
							...props.goal,
							sections: [...props.goal.sections, ""],
						})
					}
				>
					add section
				</button>
			</div>
			<br />
			<button
				onClick={() => {
					if (
						window.confirm("Do you want to delete " + props.goal.title + "?")
					) {
						props.deleteGoal();
					}
				}}
				className="mt-5 w-full bg-red-500 opacity-60 hover:opacity-100 p-4 rounded transition-opacity"
			>
				delete Goal
			</button>
			<hr className="border-1 dark:border-slate-700 border-slate-200 mt-5 mb-5" />
		</div>
	);
};

const App = () => {
	const [importValue, setImportValue] = useState("");
	const [goals, setGoals] = useState<{ title: string; sections: string[] }[]>(
		[]
	);
	const [result, setResult] = useState("");
	useEffect(() => {
		const storedItem = window.localStorage.getItem("goals");
		if (storedItem) {
			setGoals(JSON.parse(storedItem));
		}
	}, []);
	useEffect(() => {
		if (goals.length) {
			let result = "";
			window.localStorage.setItem("goals", JSON.stringify(goals));
			goals.forEach((goal) => {
				result += "┏━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━\n";
				chunk(goal.title, 80).forEach((title) => {
					result += `┃ ${title}\n`;
				});
				goal.sections.forEach((section) => {
					result += "┃ ───────────────\n";
					chunk(section, 76).forEach((s) => {
						result += "┃ " + s + "\n";
					});
				});
				result += "┗━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━\n\n";
			});
			setResult(result);
		} else {
			setResult("");
		}
	}, [goals]);
	return (
		<main className="w-full p-8 dark:bg-slate-800 bg-white dark:text-white flex justify-center">
			<div className="w-full md:w-1/2 mt-20">
				{goals.map((goal, i) => (
					<Goal
						goal={goal}
						key={i}
						setGoal={(newGoal) =>
							setGoals(
								goals.map((oldGoal, index) => {
									if (index === i) {
										return newGoal;
									}
									return oldGoal;
								})
							)
						}
						deleteGoal={() => setGoals(goals.filter((_, index) => index !== i))}
					/>
				))}
				<button
					className="w-full bg-blue-500 rounded mb-5 mt-5 p-4"
					onClick={() => setGoals([...goals, { title: "", sections: [] }])}
				>
					add goal
				</button>
				{goals.length ? (
					<button
						className="w-full bg-red-500 mb-5 p-4 rounded"
						onClick={() => {
							if (window.confirm("Do you want to delete all goals?")) {
								setGoals([]);
								window.localStorage.setItem("goals", JSON.stringify([]));
							}
						}}
					>
						delete all goals
					</button>
				) : null}
				<hr className="border-slate-200 dark:border-slate-700" />
				<textarea
					className="mt-4"
					onClick={({ target }) => {
						(target as any).select();
						navigator.clipboard.writeText(result);
					}}
					readOnly
					value={result}
				/>

				<textarea
					value={importValue}
					onChange={({ target: { value } }) => setImportValue(value)}
					className="h-4 mt-8 p-4"
					placeholder="Paste boxes to import"
				/>
				<button
					onClick={() => {
						const goals: { sections: string[]; title: string }[] = [];
						let currentGoal = { sections: [], title: "" };
						let currentObj: "none" | "title" | "section" = "none";
						importValue.split("\n").forEach((line) => {
							if (line.slice(0, "┏━".length) === "┏━") {
								currentGoal = { sections: [], title: "" };
								currentObj = "title";
							} else if (
								line.slice(0, "┃ ─".length) === "┃ ─" &&
								(currentObj === "section" || currentObj === "title")
							) {
								currentObj = "section";
								(currentGoal.sections as any)[currentGoal.sections.length] = "";
							} else if (
								line.slice(0, "┃ ".length) === "┃ " &&
								(currentObj === "section" || currentObj === "title")
							) {
								if (currentObj === "title") {
									currentGoal.title += line
										.slice("┃ ".length)
										.split("\n")
										.join(" ")
										.split("  ")
										.join(" ");
								} else if (currentObj === "section") {
									(currentGoal.sections as any)[
										currentGoal.sections.length - 1
									] += line
										.slice("┃ ".length)
										.split("\n")
										.join(" ")
										.split("  ")
										.join(" ");
								}
							} else if (
								line.slice(0, "┗━━".length) === "┗━━" &&
								(currentObj === "section" || currentObj === "title")
							) {
								goals.push(currentGoal);
								currentObj = "none";
								currentGoal = { sections: [], title: "" };
							} else if (line !== "") {
								console.log("import: unrecognized line: " + line);
							}
						});
						if (goals.length) {
							setGoals(goals);
							setImportValue("");
						}
					}}
					className="w-full bg-blue-500 p-4 mt-5 rounded"
				>
					Import
				</button>
				<footer className="sticky top-0 w-full  mt-5 mb-5">
					<p className="text-center">
						<a
							target="_blank"
							rel="noopener noreferrer"
							className="text-blue-700 dark:text-blue-300"
							href="https://github.com/janic0/BoxCreator"
						>
							View Source
						</a>
					</p>
				</footer>
			</div>
		</main>
	);
};

export default App;
